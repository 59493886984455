/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;


/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
import Gate from "./Gate";
import VueI18n from "vue-i18n";

Vue.prototype.$gate = new Gate(window.user);

Vue.use(VueI18n);


/**
 * Load locales from locales folder
 */
function loadLocaleMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach(key => {
        const matchedJson = key.match(/([A-Za-z0-9-_]+)\./i);
        const matchedLocale = key.match(/\/([A-Za-z0-9-_]+)\//i);

        if (matchedLocale && matchedLocale.length > 1 && matchedJson && matchedJson.length > 1) {
            const locale = matchedLocale[1];
            const jsonName = matchedJson[1];

            messages[locale] = messages[locale] ? messages[locale] : {};
            messages[locale][jsonName] = locales(key);
        }
    });
    return messages;
}

const i18n = new VueI18n({
    locale: "ja",
    fallbackLocale: "ja",
    messages: loadLocaleMessages()
});

Vue.use(VueRouter);
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    routes
});

import VueProgressBar from 'vue-progressbar';
Vue.use(VueProgressBar, {
    color: '#61c495',
    failedColor: '#cb5d5d',
    thickness: '6px',
});

router.beforeEach(async(to, from, next) => {
    next();
})
// Routes End
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('dashboard', require('./components/Dashboard.vue'));
Vue.component('booking-list', require('./components/BookingList.vue'));
Vue.component(
    'not-found',
    require('./components/NotFound.vue').default
);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router,
    i18n,
});
