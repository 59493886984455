<template>
    <div class="container-fluid">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">{{ $t("booking.title") }}</h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">{{ $t("booking.table_title") }}</h3>
                        <div class="card-tools">
                            <div class="input-group input-group-sm" style="width: 300px;">
                                <input type="text" name="table_search" class="form-control float-right"
                                       :placeholder="$t('common.search_placeholder')"
                                       v-model="searchValue"
                                       v-on:keyup.enter="loadByKeyword">

                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-default" v-on:click="loadByKeyword">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body table-responsive p-0">
                        <table class="table text-nowrap">
                            <thead>
                            <tr class="table-secondary">
                                <th style="width: 10px">{{ $t("booking.id") }}</th>
                                <th>{{ $t("booking.booking_code") }}</th>
                                <th>{{ $t("booking.customer") }}</th>
                                <th>{{ $t("booking.room_type") }}</th>
                                <th>{{ $t("booking.checkin_date") }}</th>
                                <th>{{ $t("booking.app_checkin_datetime") }}</th>
                                <th>{{ $t("booking.checkout_date") }}</th>
                                <th>{{ $t("booking.app_checkout_datetime") }}</th>
                                <th>{{ $t("booking.number_of_people") }}</th>
                                <th>{{ $t("booking.total_cost") }}</th>
                                <th>{{ $t("booking.status") }}</th>
                            </tr>
                            </thead>
                            <tbody class="table-hover">
                            <tr v-if="bookings && bookings.data" v-for="(booking , index) in bookings.data"
                                :key="index">
                                <td>{{ booking.id }}</td>
                                <td>{{ booking.booking_code }}</td>
                                <td v-if="booking.checkin_customer_id != null"
                                    v-for="(customer, index) in booking.customers" :key="index">
                                    <span v-if="customer.id == booking.checkin_customer_id">
                                        {{ customer.name }}
                                    </span>
                                </td>
                                <td v-if="booking.checkin_customer_id == null">
                                    {{ booking.customers[0].name }}
                                </td>
                                <td>{{ booking.room_type }}</td>
                                <td>{{ booking.checkin_date }}</td>
                                <td>{{ booking.app_checkin_datetime }}</td>
                                <td>{{ booking.checkout_date }}</td>
                                <td>{{ booking.app_checkout_datetime }}</td>
                                <td>{{ booking.number_of_people }}</td>
                                <td>{{
                                        booking.total_cost.toLocaleString('ja-JP', {
                                            style: 'currency',
                                            currency: 'JPY',
                                        })
                                    }}
                                </td>
                                <td>{{ $t(`booking.status_${booking.status}`) }}</td>
                            </tr>
                            <tr v-if="!loading && (!bookings || !bookings.data || bookings.data.lenght == 0)">
                                <td colspan="10" class="text-center">No data</td>
                            </tr>
                            <tr v-if="loading">
                                <td colspan="10" class="text-center">
                                    <span class="spinner-border spinner-border-sm">

                                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /.card-body -->

                    <div class="card-body">
                        <pagination class="justify-content-end" :limit="3"
                                    :show-disable="true"
                                    :data="bookings.pagination"
                                    @pagination-change-page="getResults"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pagination from 'laravel-vue-pagination';

export default {
    name: 'bookings',
    components: {
        pagination
    },
    data() {
        return {
            bookings: {
                type: Object,
                default: null
            },
            loading: true,
            searchValue: '',
            keyword: ''
        }
    },
    mounted() {
        console.log('Mounted component');
    },
    created() {
        this.getResults();
    },
    methods: {
        getResults(page = 1, pageSize = 15) {
            let url = `/api/v1/admin/bookings?page=${page}&pageSize=${pageSize}`;
            if (this.keyword) {
                url += `&keyword=${this.keyword}`;
            }

            this.$Progress.start();
            axios
                .get(url)
                .then(({data}) => {
                    this.bookings = data;
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch(e => {
                    this.loading = false;
                    this.$Progress.fail();
                    console.error(e);
                });
        },
        loadByKeyword() {
            this.keyword = this.searchValue;
            this.getResults();
        }
    }
}
</script>
